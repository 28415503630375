.login-layout {
    background-color: $--navy-blue-200;
}
.login-page {
    background-color: $--navy-blue-200;
}

.phone-container {
    max-width: 80%;
}

.active-method {
    border: 3px solid $--text-orange;
}

.editor-img {
    img{
        width: 100%;
    }
}