* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: white;
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: var(--primary-color);
}
p {
    margin: 0;
    padding: 0;
}

.layout-wrapper {
    min-height: 100vh;
    background-color: $--text-dark-gray;
}

.home-carusel-container {
    .p-divider .p-divider-content {
        background-color: $--text-dark-gray;
    }
    .p-divider.p-divider-horizontal:before {
        border-color: $--dark-gray;
    }

    .p-carousel .p-carousel-content .p-carousel-prev,
    .p-carousel .p-carousel-content .p-carousel-next {
        display: none;
    }
}

.accordion-content {
    display: block;
    height: auto;
    animation: 7s delay-overflow;
    &.closed {
        overflow: hidden;
        height: 0;
    }
}
.accordion-title {
    background: url("/images/down-arrow.svg") no-repeat center right;
    &.closed {
        background: url("/images/up-arrow.svg") no-repeat center right;
    }
}

.servis-list-image-container {
    height: 110px;
    background: url("/images/service-list-background.png") no-repeat center center;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.grid-image-container {
    background-position: center center;
    background-size: cover;
    background-position: center center;
    height: 170px;
}
.detail-content-container {
    width: 100%;
    max-width: 70%;
    margin: auto;
    .question-container {
        width: 100%;
        max-width: 580px;
        margin: auto;
        text-align: center;
        margin-top: 30px;
    }
}
.yellow-page-detail-content-container {
    width: 100%;
    max-width: 80%;
    margin: auto;
    margin-top: 30px;
    .question-container {
        width: 100%;
        max-width: 580px;
        margin: auto;
        text-align: center;
        margin-top: 30px;
    }
    .yellow-page-slider {
        .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
            transition: box-shadow 0.15s;
            padding: 2px;
        }
        .p-galleria .p-galleria-thumbnail-container {
            background: transparent;
        }
        .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
        .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
            display: none;
        }

        .main-image {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 89%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
        .slider-thumnail-image-container {
            background: red;
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 10px;
            img {
                width: 100%;
                max-width: 160px;
                display: block;
                opacity: 0;
            }
        }
    }
    .text-container {
        background-color: white;
        border-radius: 10px;
        .title {
            color: $--text-light-blue;
            font-weight: 300px;
            text-align: center;
            border-bottom: 1px solid $--text-light-blue;
            padding-bottom: 10px;
            width: 90%;
            margin: auto;
            margin-bottom: 15px;
        }
    }
    .ad-detail-container {
        .title {
            font-weight: 300;
            color: $--text-light-blue;
            border-bottom: 1px solid $--text-light-blue;
            margin-bottom: 10px;
        }
    }
    .add-user-container {
        background-color: white;
        border-radius: 10px;
        text-align: center;
        font-size: 14px;
        width: 100%;
        padding-top: 5px;
        .user-first-char {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin: auto;
            font-weight: 600;
            font-size: 22px;
            padding-top: 14px;
            background-color: $--text-black;
            color: white;
        }
    }
}

.search-main-container {
    position: relative;
    z-index: 1001;
    .filter-container {
        position: absolute;
        bottom: -155px;
        right: 0;
        width: 442px;
        padding: 15px;
        background-color: #9ba3ae;
        border-radius: 10px;
        color: white;
        z-index: 1003;
        button {
            width: 332px;
        }
    }
}

.empty-container-for-click {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1000;
    &.disbale {
        position: relative;
        width: 0;
        height: 0;
        overflow: hidden;
    }
}

.empty-container-for-click-for-profiler {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 799;
    &.disbale {
        position: relative;
        width: 0;
        height: 0;
        overflow: hidden;
    }
}

.education-popup {
    img {
        border-radius: 50%;
        width: 150px;
    }
}
.service-list-container {
    position: relative;
    min-height: 190px;
    background-color: white;
    padding: 15px 15px 15px 80px;
    text-align: center;
    .servis-list-left {
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
        height: 100%;
        background: #9ba3ae;
        color: white;
        text-align: center;
        padding: 15px 0;
        font-size: 14px;
        i {
            margin-bottom: 25px;
            font-size: 18px;
        }
        p {
            font-size: 20px;
            font-weight: 500;
        }
        &.highlight {
            background-color: $--text-orange;
        }
    }
    .text-container {
        font-size: 12px;
        margin-top: 20px;
    }
    .hours-container {
        span {
            padding: 0 8px;
            border-right: 1px solid #111727;
            &:last-of-type {
                border: none;
            }
        }
    }

    .p-inline-message .p-inline-message-text {
        font-size: 11px;
    }

    .p-inline-message.p-inline-message-info {
        color: black;
    }
}
.table-contaner {
    border-radius: 10px;
    margin-bottom: 10px;
    table {
        width: 100%;
        tr {
            td {
                height: 50px;
                padding: 10px;
                vertical-align: middle;
                font-size: 14px;
            }
        }
    }
}

.food-menu-list-container {
    margin-top: 20px;
    .disable-opacity {
        opacity: 0.6;
    }
    .accordion-title-container {
        width: 90%;
        margin: auto;
        background-color: #e5e7eb;
        border-radius: 10px;
        text-align: center;
        padding: 10px;
        font-weight: 300;
        font-size: 14px;
        cursor: pointer;
        margin-bottom: 20px;
        background: url("/images/up-arrow-mini.svg") no-repeat center right 20px #e5e7eb;
        &.closed {
            background: url("/images/down-arrow-mini.svg") no-repeat center right 20px #e5e7eb;
        }
    }
    .accordion-detail-container {
        height: auto;
        &.closed {
            height: 0;
            overflow: hidden;
        }
        .items {
            width: 85%;
            margin: auto;
            background: $--dark-gray;
            padding: 5px 10px;
            font-weight: 300;
            font-size: 14px;
            color: white;
            border-radius: 5px;
            text-align: center;
            margin-bottom: 10px;
            span {
                font-size: 14px;
            }
            &.second {
                background: #ffd5c0;
                color: $--dark-gray;
            }
        }
    }
}
// .shortcut-callender {
//     &.react-calendar {
//         width: 300px;
//         max-width: 100%;
//         background: transparent;
//         border: none;
//         font-family: Arial, Helvetica, sans-serif;
//         line-height: 1.125em;
//     }
//     .react-calendar__month-view__weekdays {
//         text-align: center;
//         text-transform: uppercase;
//         font: inherit;
//         font-size: 10px;
//         font-weight: 400;
//     }
//     .react-calendar__tile--active {
//         background: $--text-orange;
//         color: white;
//         border-radius: 5px;
//     }
//     .react-calendar__tile {
//         max-width: 100%;
//         padding: 5px;
//         margin: 0;
//         line-height: 16px;
//         font: inherit;
//         font-size: 0.833em;
//     }
//     // .react-calendar__tile--now {
//     //     background: #006edc;
//     //     color: white;
//     //     border-radius: 5px;
//     // }
//     // .react-calendar__navigation {
//     //     display: flex;
//     //     height: 30px;
//     //     margin-bottom: 0;
//     //     ._prev2-button {
//     //         display: none;
//     //     }
//     // }
//     .react-calendar__navigation button:disabled {
//         background-color: transparent;
//     }

//     .react-calendar__navigation button:enabled:hover,
//     .react-calendar__navigation button:enabled:focus {
//         background-color: transparent;
//     }
//     .react-calendar__tile--hasActive:enabled:hover,
//     .react-calendar__tile--hasActive:enabled:focus {
//         background: $--text-orange;
//     }
//     .react-calendar__tile--hasActive {
//         background: $--text-orange;
//         border-radius: 5px;
//         color: white;
//     }
//     .react-calendar__tile--now {
//         background: transparent;
//         color: inherit;
//         border-radius: 5px;
//     }
//     .react-calendar__tile--now:enabled:hover,
//     .react-calendar__tile--now:enabled:focus {
//         background: transparent;
//     }
// }

.shortcut-callender {
    display: block;
    margin-top: 20px;
    .p-datepicker .p-datepicker-header {
        display: none;
    }
    .p-datepicker table th {
        padding: 0;
        padding-bottom:30px;
        font-weight: 400;
        font-size: 12px;
    }
    .p-datepicker table td {
        padding: 0;
    }
    .p-datepicker {
        padding: 0;
        background: transparent;
        color: #212529;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }
    .p-datepicker table td > span.p-highlight {
        color: black;
        background:#FF9D6A
    }
    .p-datepicker table td span {
        display: block;
        width: auto;
        height: auto;
        border-radius: 4px;
        transition: box-shadow 0.15s;
        border: 1px solid transparent;
        line-height: 16px;
        // padding: 5px;
        text-align: center;
        padding: 5px 5px 5px 5px;
    }
    .p-datepicker {
        border: none;
    }
}

.shortcut-holiday-container {
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 20px;

    .top-container {
        background-color: $--dark-gray;
        padding: 5px;
    }

    .buttom-container {
        background-color: #FF9D6A;
        color: black;
        padding: 5px;
    }    
}

.square {
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 4px solid $--text-orange;
    background-color: white;
    // background: url("/images/step-counter-background.svg") no-repeat top 15px center white;
    // background-size: 56px 56px;
    background:url('/images/step.gif') no-repeat top 15px center white;
    background-size: 56px;
}

.step-counter-container {
    width: 100%;
    max-width: 750px;
    margin: auto;
    .user-score {
        position: relative;
        width: 404px;
        height: 202px;
        margin: auto;     
        .text-container {
            text-align: center;
            margin-top: 60%;
            font-size: 12px;      
            span {
                color: $--light-orange;
            }
        }
        .left-back {
            @extend .square;
            left: 0;
            top: 50px;
        }
        .right-back {
            @extend .square;
            left: inherit;
            right: 0;
            top: 50px;
        }
        .top-front {
            @extend .square;
            width: 200px;
            height: 200px;
            left: 0;
            right: 0;
            margin: auto;
            border: 4px solid $--text-orange;
            // background: url("/images/step-counter-background.svg") no-repeat top 30px center white;
            background:url('/images/step.gif') no-repeat top 10px center white;
            background-size: 120px;
            .text-container {
                font-size: 16px;
                span {
                    color: $--light-orange;
                }
            }
        }
    }
    .user-list-items {
        .user-round {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $--light-orange;
            color: white;
            font-weight: 300;
            font-size: 22px;
            text-align: center;
            padding-top: 10px;
            margin-right: 10px;
        }
        .user-score-item {
            color: $--text-orange;
            font-weight: 300;
            font-size: 14px;
        }
        .step-divider {
            width: 80%;
            border-bottom: 1px solid $--text-ligth-gray;
            margin: 30px auto;
        }
    }
}

.main-slider-image-container {
    width: 100%;
    // padding-bottom: 73.25%;
    // background-position: center center;
    // background-size: cover;
    // background-repeat: no-repeat;
    border-radius: 10px; 
    img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
}

.main-subslider-image-container {
    width: 100%;
    height: 130px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.main-page-quick-answer {
    width: 100%;
    min-height: 150px;
    background: url("/images/quick-data.png") no-repeat center center white;
    background-size: cover;
    border-radius: 10px;
    font-weight: 600;
    text-align: center;
    padding: 10px 30px;
    .text-container {
        font-size: 14px;
        margin-top: 20px;
    }
}

.main-page-birthday-container {
    width: 100%;
    min-height: 150px;
    background: url("/images/bg-birthday.png") no-repeat center center white;
    background-size: cover;
    border-radius: 10px;
    text-align: center;
    padding: 10px 30px;
    margin-top: 20px;
    .marque-container {
        font-size: 14px;
        font-weight: 300;
        span {
            margin-right: 5px;
            background: url("/images/birthday-cake-mini.svg") no-repeat left center;
            padding-left: 25px;
        }
        img {
            margin-right: 5px;
        }
    }
    .title-container {
        font-weight: 500;
        margin-bottom: 10px;
    }
}

.main-page-meeting-container {
    width: 100%;
    min-height: 150px;
    background-color: #f4f4f4;
    // background: url("/images/bg-birthday.png") no-repeat center center;
    background-size: cover;
    border-radius: 10px;
    text-align: center;
    padding: 10px 30px;
    margin-top: 20px;
    color: #4f58ca;
    .title-container {
        font-weight: 500;
    }
    .text-item {
        font-weight: 600;
        p {
            font-weight: 300;
            color: black;
            padding-top: 5px;
        }
    }
}

.main-page-acident-container {
    width: 100%;
    min-height: 150px;
    background: url("/images/bg-acident.png") no-repeat center center;
    background-size: cover;
    border-radius: 10px;
    text-align: center;
    padding: 10px 30px;
    margin-top: 20px;
    color: $--text-orange;
    .title-container {
        font-weight: 500;
        margin-bottom: 10px;
    }
    .text-item {
        font-weight: 400;
        color: black;
        font-size: 14px;
        p {
            font-size: 28px;
            color: $--text-orange;
        }
    }
}
.main-page-slider {
    .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background: $--text-orange;
    }
    .p-galleria .p-galleria-indicators .p-galleria-indicator button {
        width:10px;
        height: 10px;
    }
}

.home-carusel-item-container {
    position: relative;
    height: auto;
    .text-item-container {
        display: table;
        width: 100%;
        height: 100%;
        max-height: 90px;
        padding-bottom: 5px;
        p {
            display: table-cell;
            width: 100%;
            height: 55px;
            vertical-align: middle;
            text-align: center;
            padding: 0 10px 0 10px;
        }
    }
    .float-text {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 70px;
        top: 0;
        left: 0;
        bottom: 0;
        padding: 15px;
        margin:auto;
    }
}
.height-full {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
}

.main-page-empty-link-container {
    height: 165px;
    .menu-link-container {
        height: 170px;
    }
}

.holiday-calendar {
    .p-datepicker {
        padding: 0;
        background: transparent;
        color: #212529;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }
    .p-datepicker .p-datepicker-header {
        padding: 0.5rem;
        color: #212529;
        background: transparent;
        font-weight: 600;
        margin: 0;
        border-bottom: 1px solid #dee2e6;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }
    .p-datepicker table {
        font-size: 12px;
        margin: 0.5rem 0;
    }
    .p-datepicker table td > span {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 4px;
        transition: box-shadow 0.15s;
        border: 1px solid transparent;
    }
    .p-datepicker table td {
        padding: 0;
    }
}

.btn-add-new-near-miss {
    width: 250px;
    height: 44px;
    margin-top: 4px;
    font-weight: 300;
}

.uploud-btn {
    .p-button {
        color: #ffffff;
        background: #1e2938;
        border: 1px solid #1e2938;
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        transition:
            background-color 0.15s,
            border-color 0.15s,
            box-shadow 0.15s;
        border-radius: 4px;
    }
}

.near-miss-detail-container {
    &.closed {
        overflow: hidden;
        height: 0;
    }
    .divider {
        width: 80%;
        border-bottom: 1px solid #121929;
        margin: 10px auto;
    }
    table {
        tr {
            td {
                vertical-align: top;
            }
        }
    }
    .detail-title {
        color: #3c82f5;
        width: 150px;
        text-align: right;
        padding-right: 10px;
        &.red {
            color: #B20101;
            width: 100px;
        }
    }
}

.name-round {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: $--light-orange;
    color: white;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    padding-top: 6px;
    margin-right: 6px;
}

.vertical-center {
    display: flex;
    align-items: center;
    justify-content:start;
    height: 100%;
    &.center {
        justify-content: center;
    }
}


.p-galleria-content{
    width: 100%;
    height: 100%;
    .p-galleria-item-wrapper{
        width: 100%;
        height: 100%;
        .p-galleria-item-container{  
            width: 100%;
            height: 100%;
            .p-galleria-item{  
                width: 100%;
                height: 100%;
                .main-slider-image-container{  
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                } 
            }
        }
    }
}


.grid-custom {
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
  }
  
  .text-right-custom {
    text-align: right;
    font-size: 0.9em; 
  }