.text-black {
  color: $--text-black;
}

.text-light-gray {
  color: $--text-ligth-gray;
}

.text-orange {
  color: $--text-orange;
}

.text-light-orange {
  color: $--light-orange;
}

.bg-orange {
  background: $--text-orange;
}

.bg-green {
  background: $--green;
}

.text-light-blue {
  color: $--text-light-blue;
}

.bg-dark-navy-blue {
  background: $--dark-gray;
}

.bg-light-gray {
  background: $--dark-gray;
}

.bg-navy-blue-100 {
  background: $--navy-blue-100;
}

.border-middle-round {
  border-radius: 10px;
}

.border-top-middle-round {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.orange-btn {
  background-color: $--text-orange;
  border-color: $--text-orange;
  &.p-button {
    border-radius: 10px;
    font-weight: 300;
  }
  &:hover {
    background-color: $--text-orange;
    opacity: 0.8;
  }
  &.p-button:enabled:hover,
  &.p-button:not(button):not(a):not(.p-disabled):hover {
    background: $--text-orange;
    color: #ffffff;
    border-color: $--text-orange;
  }
}

.display-none {
  display: none;
}

p {
  margin: 0;
  padding: 0;
}

.dark-navy-blue-btn {
  background-color: $--dark-gray;
  border-color: $--dark-gray;
  &.p-button {
    border-radius: 10px;
    font-weight: 300;
  }
  &:hover {
    background-color: $--dark-gray;
    opacity: 0.8;
  }
  &.p-button:enabled:hover,
  &.p-button:not(button):not(a):not(.p-disabled):hover {
    background: $--dark-gray;
    color: #ffffff;
    border-color: $--dark-gray;
  }
}

.ultra-light-gray-btn {
  background-color: #4c5563;
  border-color: #4c5563;
  &.p-button {
    border-radius: 10px;
    font-weight: 300;
  }
  &:hover {
    background-color: #4c5563;
    opacity: 0.8;
  }
  &.p-button:enabled:hover,
  &.p-button:not(button):not(a):not(.p-disabled):hover {
    background: #4c5563;
    color: #ffffff;
    border-color: #4c5563;
  }
}

.display-none {
  display: none;
}

.p-dialog .p-dialog-header {
  border-bottom: none;
}

.w-1-5rem {
  width: 1.3rem;
}

.p-scrollpanel-content {
  // height: calc(100% + -100px);
  width: calc(100% + 18px);
  padding: 0 18px 18px 0;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
}
.p-scrollpanel-wrapper {
  height: calc(100% + 31px);
}

.layout-menu {
  padding-top: 40px;
  overflow-y: auto;
  height: 80%;
}

.menu-parent {
  align-items: center;
  color: #121929;
  transition: 0.2s;
  padding: 0 16px;
  transition: background-color 0.15s;
  margin-bottom: 15px;
  font-weight: 600;
}
