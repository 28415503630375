.layout-topbar {
    position: fixed;
    height: 75px;
    z-index: 1002;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    // background-image: linear-gradient(180deg, #000F9F 0%, #002CA8 100%);
    transition: left $transitionDuration;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: $--navy-blue-200;
    overflow: hidden;
    .profil-photo {
        border-radius: 50%;
        border: 2px solid white;
        width: 50px;
    }

    .profil-photo-text-container {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 500;
        margin-right: 5px;
        text-align: center;
        background-color: $--text-orange;
        color: white;
        line-height: 24px;
        .first-chars {
            position: absolute;
            width: 100%;
            height: 24px;
            top:0;
            left:0;
            bottom: 0;
            right: 0;
            margin: auto;
            text-align: center;
        }
    }

    .profil-container {
        position: fixed;
        right: 0;
        top: 75px;
        width: 308px;
        height: calc(100% - 75px);
        background: url("/images/profil-background.svg") no-repeat top $--navy-blue-200;
        padding-top: 175px;
        z-index: 800;
        img {
            width: 120px;
        }
        .inner-container-text-photo {
            position: relative;
            width: 120px;
            height: 120px;
            border-radius: 15px;
            border: 2px solid $--text-orange;
            font-size: 28px;
            line-height: 28px;
            font-weight: 500;
            margin: auto;
            text-align: center;
            padding-top: 37px;
            background-color: #384150;
            margin-bottom: 20px;
            color: white;
            .first-chars {
                position: absolute;
                width: 100%;
                height: 24px;
                top:0;
                left:0;
                bottom: 0;
                right: 0;
                margin: auto;
                text-align: center;
            }
        }
        .p-divider .p-divider-content {
            background-color: $--navy-blue-200 !important;
        }
    }
    .layout-topbar-logo {
        display: flex;
        align-items: center;
        color: var(--surface-900);
        font-size: 1.5rem;
        font-weight: 500;
        width: 300px;
        border-radius: 12px;

        img {
            height: 2.5rem;
            margin-right: 0.5rem;
        }

        &:focus {
            @include focused();
        }
    }

    .layout-topbar-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        // color: white;
        cursor: pointer;
        transition: background-color $transitionDuration;
        padding: 0;
        margin: 0;

        &:hover {
            background-color: transparent;
        }

        &:focus {
            @include focused();
        }
    }

    .layout-menu-button {
        margin-left: 15px;
        display: none;
    }

    .layout-topbar-menu-button {
        display: none;

        i {
            font-size: 1.25rem;
        }
    }

    .layout-topbar-menu {
        margin: 0 0 0 auto;
        padding: 0;
        list-style: none;
        display: flex;

        .layout-topbar-button {
            margin-left: 1rem;
        }
    }
    .top-bar-right-menu {
        margin: 0 0 0 auto;
        .p-menubar {
            background: transparent;
        }
    }
}

@media (max-width: 991px) {
    .top-menu-date-container {
        display: none !important;
    }
    .layout-topbar {
        justify-content: space-between;

        .layout-topbar-logo {
            width: auto;
            order: 2;
        }

        .layout-menu-button {
            margin-left: 5px;
            display: block;
        }
    }
}
