/* General */
$fontSize: 1rem;
$borderRadius: 12px;
$transitionDuration: 0.2s;
$maskBg: rgba(0, 0, 0, 0.4);
$--dark-gray: #364152;
$--text-black: #121929;
$--text-ligth-gray: #6c7380;
$--text-dark-gray: #d2d5db;
$--text-orange: #ff8143;
$--bg-light-gray: #9ba3ae;
$--text-light-blue: #3c82f5;
$--light-orange: #FFB895;
$--navy-blue-100: #121929;
$--navy-blue-200: #111727;
$--green: #00e08f;

