.layout-sidebar {
  position: fixed;
  width: 320px;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  top: 4rem;
  transition:
    transform $transitionDuration,
    left $transitionDuration;
  background: white;
  .version {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
  }
  .layout-menu-container {
    overflow-y: auto;
    height: 98%;
    scroll-snap-type: y mandatory;
    /* scroll-padding: top right bottom left */
    scroll-padding-top: 50px;
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: lightgray;
      opacity: 0.5;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: lightgray;
      opacity: 0.5;
    }
  }
}

.layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  box-sizing: border-box;
  padding-top: 70px;
  li {
    &.layout-menuitem-category {
      margin-top: 0.75rem;
      &:first-child {
        margin-top: 0;
      }
    }
    .menu-icon {
      font-size: 25px;
      margin-right: 15px;
      margin-left: 15px;
      line-height: 25px;
    }

    .layout-menuitem-root-text {
      text-transform: uppercase;
      color: var(--text-color-secondary);
      font-weight: 400;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: $--text-black;
      transition: $transitionDuration;
      padding: 0 16px;
      transition: background-color 0.15s;
      margin-bottom: 15px;

      span {
        margin-left: 0.5rem;
      }

      .menuitem-toggle-icon {
        margin-left: auto;
      }

      &:focus {
        @include focused-inset();
      }

      &:hover {
        color: $--text-orange;
        // background-color: transparent;
        // // background-image: linear-gradient(180deg, #000f9f 0%, #002ca8 100%);
        // color: white;
      }

      &.router-link-exact-active {
        color: $--text-orange;
        padding: 0 12px;
        border-left: 4px solid $--text-orange;
        & .pi {
          color: $--text-orange;
        }
      }
    }

    &.active-menuitem {
      > a {
        .menuitem-toggle-icon {
          &:before {
            content: "\e933";
          }
        }
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &.layout-submenu-wrapper-enter {
        max-height: 0;
      }

      &.layout-submenu-wrapper-enter-active {
        overflow: hidden;
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
      }

      &.layout-submenu-wrapper-enter-done {
        transform: none;
      }

      &.layout-submenu-wrapper-exit {
        max-height: 1000px;
      }

      &.layout-submenu-wrapper-exit-active {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
      }

      ul {
        padding-left: 1rem;
      }
      & a {
        font-size: 14px;
      }
    }
  }
}
.app-versiyon-container {
  position: absolute;
  width: 100%;
  bottom: 80px;
  left: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  .circle {
    height: 25px;
    width: 25px;
    background-color: $--text-orange;
    border-radius: 50%;
    display: inline-block;
    color:white;
    padding: 3.5px 3.5px 0 3.5px;
  }
  span{
    font-size: 8px;
  }
}
